import { createContext, FC, useCallback, useState, useEffect } from 'react';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

export interface AuthContextType {
  isInitialized: boolean;
  isSignIn: boolean;
  token?: string;
  signIn: () => Promise<void>;
  signOut: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
  isInitialized: false,
  isSignIn: false,
  signIn: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
});

export const AuthProvider: FC = ({ children }) => {
  const auth = getAuth();
  const [token, setToken] = useState<string | undefined>(undefined);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const signIn = useCallback(async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      login_hint: 'user@sun-asterisk.com',
    });
    await signInWithPopup(auth, provider);
  }, [auth]);

  const signOut = useCallback(async () => {
    if (auth.currentUser) {
      await auth.signOut();
    }
  }, [auth]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      const token = await user?.getIdToken();
      setToken(token);
      setIsInitialized(true);
      console.log('AuthStateChanged token ', token);
    });
    return () => {
      unsubscribe();
    };
  }, [auth]);

  return (
    <AuthContext.Provider value={{ isSignIn: !!token, signIn, signOut, token, isInitialized }}>
      {children}
    </AuthContext.Provider>
  );
};

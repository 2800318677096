import { Suspense, lazy, useContext } from 'react';
import { ChakraProvider, Center, Spinner } from '@chakra-ui/react';
import { BrowserRouter, useRoutes, Navigate } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import PATH from './Paths';

import theme from './theme';

import { DefaultLayout } from './components/DefaultLayout';
import { AuthProvider } from './AuthContext';

import { AuthContext } from './AuthContext';
import { firebaseConfig } from './firebaseConfig';

const Dashboard = lazy(() => import('./views/Dashboard'));
const Bookmark = lazy(() => import('./views/Bookmark'));
const Info = lazy(() => import('./views/Info'));
const Login = lazy(() => import('./views/Login'));

initializeApp(firebaseConfig);

const Loading = () => {
  return (
    <Center w="full" minH="100vh">
      <Spinner mt="-10" />
    </Center>
  );
};

const Router = () => {
  const { isSignIn } = useContext(AuthContext);

  const mainRoutes: RouteObject = {
    path: '/',
    element: <DefaultLayout />,
    children: [
      {
        index: true,
        path: PATH.DASHBOARD,
        element: (
          <Suspense fallback={<Loading />}>
            <Dashboard />
          </Suspense>
        ),
      },
      {
        path: PATH.BOOKMARKS,
        element: (
          <Suspense fallback={<Loading />}>
            <Bookmark />
          </Suspense>
        ),
      },
      {
        path: PATH.INFO,
        element: (
          <Suspense fallback={<Loading />}>
            <Info />
          </Suspense>
        ),
      },
    ],
  };
  const authRoutes: RouteObject = {
    path: '/',
    element: <DefaultLayout />,
    children: [
      { path: '*', element: <Navigate to="/" /> },
      {
        path: '/',
        index: true,
        element: (
          <Suspense fallback={<Loading />}>
            <Login />
          </Suspense>
        ),
      },
    ],
  };
  const routing = useRoutes([isSignIn ? mainRoutes : authRoutes]);
  return <>{routing}</>;
};

const App = () => {
  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <AuthProvider>
          <Router />
        </AuthProvider>
      </ChakraProvider>
    </BrowserRouter>
  );
};

export default App;

import { FC, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import {
  Menu,
  useColorModeValue,
  Text,
  Heading,
  Flex,
  IconButton,
  useColorMode,
  HStack,
  Avatar,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Button,
} from '@chakra-ui/react';
import { CgSun, CgDarkMode } from 'react-icons/cg';

import { getAuth } from 'firebase/auth';
import { AuthContext } from '../AuthContext';

// import PATH from '../Paths';

// import MenuIcon, { MenuIconProps } from './MenuIcon';

// const menuItems: MenuIconProps[] = [
//   {
//     label: 'Math Pix',
//     path: PATH.DASHBOARD,
//     icon: <CgSearchLoading />,
//   },
//   // {
//   //   label: 'Book Mark',
//   //   path: PATH.BOOKMARKS,
//   //   icon: <CgBookmark />,
//   // },
//   {
//     label: 'Feedback',
//     path: PATH.INFO,
//     icon: <CgInfo />,
//   },
// ];

export const DefaultLayout: FC = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const bgColorLeft = useColorModeValue('gray.50', 'gray.700');
  const bgColorContent = useColorModeValue('white', 'inherit');
  const { isSignIn, signOut } = useContext(AuthContext);
  const auth = getAuth();
  return (
    <Flex minH="100vh" direction="column">
      <Flex
        // position="fixed"
        // top="0"
        // left="0"
        // bottom="0"
        // width="20"
        bg={bgColorLeft}
        py="4"
        px="4"
        direction="row"
        align="center"
        justify="space-between">
        <HStack>
          <Heading as="h6" size="md" textAlign="center">
            MaTEX
          </Heading>
          <Text fontSize="xs">(beta)</Text>
        </HStack>

        {/* <HStack spacing="8">
          {menuItems.map((item) => (
            <MenuIcon key={item.path} {...item} />
          ))}
        </HStack> */}
        <HStack spacing="6">
          <Button
            size={'sm'}
            variant={'ghost'}
            onClick={() => window.open('https://forms.gle/Zuy4HfFU8EzVnnfx8', '_blank')}>
            (Feedback)
          </Button>
          <IconButton
            aria-label="Color mode"
            icon={colorMode === 'dark' ? <CgSun /> : <CgDarkMode />}
            onClick={toggleColorMode}
          />
          <Menu>
            <MenuButton>
              <Avatar
                size={'sm'}
                src={
                  isSignIn && auth.currentUser?.photoURL ? auth.currentUser?.photoURL : undefined
                }
                title={auth.currentUser?.displayName || undefined}
              />
            </MenuButton>

            <MenuList>
              {isSignIn && (
                <>
                  <MenuItem>{auth.currentUser?.displayName}</MenuItem>
                  <MenuItem>{auth.currentUser?.email}</MenuItem>
                  <MenuDivider />
                  <MenuItem onClick={signOut}>Sign out</MenuItem>
                </>
              )}
            </MenuList>
          </Menu>
        </HStack>
      </Flex>
      <Flex bg={bgColorContent} flex="1" p="4">
        <Outlet />
      </Flex>
    </Flex>
  );
};
